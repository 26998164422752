
export const useHandleValidation = (valid, message, error, emit, previous) => { 
    if(!valid) {
        error.show = true
        error.message = message
        emit('error', message)
    }
    else if(previous === undefined || previous) {
        error.show = false
        error.message = ''
    }
    return valid
}
